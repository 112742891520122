export default [
  {
    path: '',
    name: 'home',
    meta: {
      title: 'Home',
      scrollToTop: true,
    },
    component: () =>
      import(/* webpackChunkName: "home-view" */ './views/HomeScreen.vue'),
  },
  {
    path: '/games',
    name: 'games',
    meta: {
      title: 'Events',
      scrollToTop: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "schedule-view" */ './views/UpcomingScreen.vue'
      ),
  },
  {
    path: '/packages',
    name: 'packages',
    meta: {
      title: 'Packages',
      scrollToTop: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "schedule-view" */ './views/PackagesScreen.vue'
      ),
  },
  {
    path: '/vod_library',
    name: 'vod Library',
    meta: {
      title: 'Clips',
      scrollToTop: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "schedule-view" */ './views/VodLibraryScreen.vue'
      ),
  },
  {
    path: '/watch/:id(\\d+)',
    name: 'watch',
    meta: {
      title: 'Watch',
    },
    component: () =>
      import(
        /* webpackChunkName: "watch-view" */ './views/WatchGameScreen.vue'
      ),
  },
  {
    path: '/watch_embed/:id(\\d+)',
    name: 'watch',
    meta: {
      title: 'Watch Embed',
      plainLayout: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "watch-view" */ './views/WatchEmbedScreen.vue'
      ),
  },
  {
    path: '/watch_library/:id(\\d+)',
    name: 'watch library',
    meta: {
      title: 'Watch',
    },
    component: () =>
      import(
        /* webpackChunkName: "watch-view" */ './views/WatchLibraryScreen.vue'
      ),
  },
  {
    path: '/vod',
    name: 'On-demand',
    meta: {
      title: 'On-demand',
      scrollToTop: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "schedule-view" */ './views/VodLiveGamesScreen.vue'
      ),
  },
  {
    path: '/schedule',
    name: 'Schedule',
    meta: {
      title: 'Schedule',
      scrollToTop: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "schedule-view" */ '@/components/base-components/Favorites/ScreenView.vue'
      ),
    children: [
      {
        path: '',
        name: 'live',
        component: () =>
          import(
            /* webpackChunkName: "schedule-view" */ '@/components/base-components/Favorites/ScreenView.vue'
          ),
      },
      {
        path: 'live',
        name: 'live',
        component: () =>
          import(
            /* webpackChunkName: "schedule-view" */ '@/components/base-components/Favorites/ScreenView.vue'
          ),
      },
      {
        path: 'upcoming',
        name: 'upcoming',
        component: () =>
          import(
            /* webpackChunkName: "schedule-view" */ '@/components/base-components/Favorites/ScreenView.vue'
          ),
      },
      {
        path: 'vod',
        name: 'vod',
        component: () =>
          import(
            /* webpackChunkName: "schedule-view" */ '@/components/base-components/Favorites/ScreenView.vue'
          ),
      },
    ],
  },
  {
    name: 'Favorites',
    path: '/favorites',
    meta: {
      title: 'Favorites',
      scrollToTop: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "schedule-view" */ '@/components/base-components/Favorites/FavoritesScreenView.vue'
      ),
    children: [
      {
        path: '',
        name: 'all',
        component: () =>
          import(
            /* webpackChunkName: "schedule-view" */ '@/components/base-components/Favorites/FavoritesScreenView.vue'
          ),
      },
      {
        path: 'live',
        name: 'live',
        component: () =>
          import(
            /* webpackChunkName: "schedule-view" */ '@/components/base-components/Favorites/FavoritesScreenView.vue'
          ),
      },
      {
        path: 'upcoming',
        name: 'upcoming',
        component: () =>
          import(
            /* webpackChunkName: "schedule-view" */ '@/components/base-components/Favorites/FavoritesScreenView.vue'
          ),
      },
      {
        path: 'vod',
        name: 'vod',
        component: () =>
          import(
            /* webpackChunkName: "schedule-view" */ '@/components/base-components/Favorites/FavoritesScreenView.vue'
          ),
      },
    ],
  },
];
